import React from 'react';
// import PPMurl from '../../assets/followone-ppm.pdf'
import ByLaws from '../../assets/followone-by-laws.pdf'
import IncorporationCert from '../../assets/followone-incorporation-certificate.pdf'
import SuperVoting from '../../assets/followone-series-super-voting-preferred-stock.pdf'

import classes from './PPM.module.scss';

const PPM = props => {
  return (
    <div className={classes.ppm_documentation}>
      <h4>PPM</h4>

      <div className={classes.ppm_documentation_flex}>
        {/* <a className={classes.button_ppm} href={PPMurl} target="_blank" rel="noopener noreferrer"><i className="fal fa-file-alt"></i> <span>PPM</span></a> */}
        {/* <button className={classes.button_ppm}><i className="fal fa-file-alt"></i> <span>Exhibit A: Subscription Agreement</span></button>
        <a className={classes.button_ppm} href={IncorporationCert} target="_blank" rel="noopener noreferrer"><i className="fal fa-file-alt"></i> <span>Exhibit B: Certificate of Incorporation of Followone, Inc.</span></a>
        <a className={classes.button_ppm} href={SuperVoting} target="_blank" rel="noopener noreferrer"><i className="fal fa-file-alt"></i> <span>Exhibit B: Certificate of Designations of Series Super Voting Preferred Stock</span></a>
        <a className={classes.button_ppm} href={ByLaws} target="_blank" rel="noopener noreferrer"><i className="fal fa-file-alt"></i> <span>Exhibit C: Bylaws of Followone, Inc.</span></a> */}
      </div>
    </div>
  );
}

export default PPM; 
import classes from './Investors.module.scss';
import HomeHeader from '../Home/Components/HomeHeader';
import { AuthContext } from '../../shared/context/auth-context';
import { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';

import Modal from '../../shared/components/UIElements/Modal';
import LogInModal from '../../shared/components/Modals/LogInModal';
import ForgotPassword from '../../shared/components/Modals/ForgotPassword';
import ResetPassword from '../../shared/components/Modals/ResetPassword';

// https://react-pdf-viewer.dev/docs/basic-usage/
import PDF from "../../assets/pitch-deck-invest-3-5-25.pdf";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";

const Investors = () => {
    const auth = useContext(AuthContext);
    const params = useParams();

    const [signInModal, setSignInModal] = useState(false);
    const [forgotPassModal, setForgotPassModal] = useState(false);
    const [resetPassModal, setResetPassModal] = useState(false);

    const token = params.token;

    useEffect(() => {
        if (token) {
            setResetPassModal(true);
        }
    }, [token])

    const openSignInModalHandler = () => {
        setSignInModal(true);
        setForgotPassModal(false);
        setResetPassModal(false);
    }

    const closeSignInModalHandler = () => {
        setSignInModal(false);
    }

    const openForgotPassModalHanlder = () => {
        setSignInModal(false);
        setForgotPassModal(true);
    }

    const closeForgotPassModalHanlder = () => {
        setForgotPassModal(false);
    }

    const closeResetPassModalHandler = () => {
        setResetPassModal(false);
    }


    return (
        <div className={classes.Investors}>
            <HomeHeader
                isAuth={auth.isLoggedIn}
                openSignInModalHandler={openSignInModalHandler}
            />

            <h2 className={classes.pitchDeckTitle}>PITCH DECK</h2>

            <div className={classes.profileDetailsPresentation}>
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                    <div className={classes.pdfSize}>
                        <Viewer 
                            fileUrl={PDF}
                        />
                    </div>
                </Worker>
            </div>


            <Modal
                show={signInModal}
                onCancel={closeSignInModalHandler}
                header="Sign In"
            >
                <LogInModal
                    openForgotPass={openForgotPassModalHanlder}
                />
            </Modal>


            <Modal
                show={forgotPassModal}
                onCancel={closeForgotPassModalHanlder}
                header="Forgot Password"
            >
                <ForgotPassword
                    openSignInModal={openSignInModalHandler}
                />
            </Modal>

            <Modal
                show={resetPassModal}
                onCancel={closeResetPassModalHandler}
                header="Reset Password"
            >
                <ResetPassword token={params.token} openSignInModal={openSignInModalHandler} />
            </Modal>
        </div>
    );
};

export default Investors;

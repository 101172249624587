import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import numeral from "numeral";

import defaultUserPhoto from "../../../assets/default-user.jpg";
import PDF from "../../../assets/pitch-deck-invest-3-5-25.pdf";
// import DocumentPPM from '../../../assets/followone-ppm.pdf';
import Button from "../../../shared/components/UIElements/Button";
import { Link } from "react-router-dom";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import classes from "./ProfileDetails.module.scss";

// https://react-pdf-viewer.dev/docs/basic-usage/
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";

const ProfileDetails = (props) => {
  const { app, user, offer } = props;
  const history = useHistory();
  const { sendRequest } = useHttpClient();

  const [pendingShares, setPendingShares] = useState(0);

  useEffect(() => {
    const fetchTransactions = async () => {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL +
          "/users/" +
          user._id +
          "/transactions/equity"
      );

      let totalPendingShares = 0;

      responseData.data.transactions.forEach((t) => {
        if (t.transactionStatus !== "completed") {
          totalPendingShares =
            totalPendingShares +
            (t.typeOfShare === "preffered" ? t.noOfShares * 10 : t.noOfShares);
        }
      });

      setPendingShares(totalPendingShares);
    };

    fetchTransactions();
  }, [sendRequest, user]);

  const onUpdateDocuments = () => {
    history.push("/verification-process");
  };

  const onGetPaymentDetails = (id) => {
    history.push(`/payment-information/${id}`);
  };

  if (!props.active) {
    return null;
  }

  const pendingPayTrans = user.transactions.filter(
    (trans) => trans.isPaid === false && trans.typeOfTransaction !== "bonus"
  );

  return (
    <div className={classes.profileDetails}>
      <div className={classes.profileId}>
        {user.photo === "default-user.jpg" ? (
          <img src={defaultUserPhoto} alt="Logged In User" />
        ) : (
          <img
            src={`https://followoneapps.blob.core.windows.net/invest/public/img/users/${user.photo}`}
            alt="Logged In User"
          />
        )}
        <h3>
          {user.firstName} {user.lastName} <br />
          <span>{user.jobTitle}</span>
        </h3>
      </div>

      <h4>Profile Details</h4>

      {/* <div className={classes.profileNotification}><strong>Followone Inc. and COVID-19</strong><br /> Due to COVID-19 pandemic, updates regarding to the IPO information will be posted soon.</div> */}

      <div className={classes.grid}>
        <div
          className={[classes.profileDetailsBox, classes.profileDetailsBoxLeft]
            .join(",")
            .replace(",", " ")}
        >
          <div>
            <p>Account Summary</p>
            <span>
              {numeral(user.totalShares).format("0,0")} <span>Shares</span>
            </span>

            {pendingShares > 0 && (
              <table className={classes.pending}>
                <tbody>
                  <tr>
                    <td>Pending Shares:</td>
                    <td>{numeral(pendingShares).format("0,0")}</td>
                  </tr>

                  <tr>
                    <td>Pending Value:</td>
                    <td>
                      $
                      {numeral(pendingShares * offer.priceShare).format(
                        "0,0.00"
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            )}

            <p style={{ marginTop: "2rem" }}>Total Equity Value</p>
            <span style={{ color: "#0083b0" }}>
              ${numeral(user.totalShares * offer.priceShare).format("0,0.00")}
            </span>

            <p style={{ marginTop: "2rem" }}>Total Gain</p>
            <span style={{ color: "#6EC568" }}>
              $
              {numeral(
                user.totalShares * offer.priceShare - user.investmentCost
              ).format("0,0.00")}
            </span>
          </div>
        </div>

        <div
          className={[classes.profileDetailsBox, classes.profileDetailsBoxLeft]
            .join(",")
            .replace(",", " ")}
        >
          <div>
            <p>Followone (FO) Stock Summary</p>
            <span style={{ color: "#0083b0" }}>
              ${numeral(offer.priceShare).format("0,0.00")} <span>/ Share</span>
            </span>

            <p style={{ marginTop: "2rem" }}>Outstanding Shares</p>
            <span>
              ${numeral(app.outstandingCommonShares).format("0,0")}
              <span></span>
            </span>

            <p style={{ marginTop: "2rem" }}>Market Cap.</p>
            <span>
              $
              {numeral(app.outstandingCommonShares * offer.priceShare).format(
                "0,0"
              )}
              <span></span>
            </span>

            {/* <Button to="/buy-shares" color="green">Buy Shares</Button> */}
          </div>
        </div>

        {/* <div className={[classes.profileDetailsBox, classes.enterpriseValue].join(',').replace(',', ' ')}>
                    <div>
                        <p>Followone Enterprise Value</p>
                        <span style={{color: '#ffffff'}}>${numeral(app.outstandingCommonShares * app.enterpriseValue).format('0,0')}</span>
                    </div>
                </div> */}

        <div
          className={[classes.profileDetailsBox, classes.equityInvestments]
            .join(",")
            .replace(",", " ")}
        >
          <div>
            <i className="fal fa-chart-line"></i>
            <p>
              Equity <br />
              Investments
            </p>
            <Button color="transparent-white" to="/equity-investments">
              View Investments
            </Button>
          </div>
        </div>

        {user.hasRevenueInvestment && (
          <div
            className={[classes.profileDetailsBox, classes.revenueInvestments]
              .join(",")
              .replace(",", " ")}
          >
            <div>
              <i className="fal fa-chart-bar"></i>
              <p>
                Revenue <br />
                Investments
              </p>
              <Button color="transparent-white" to="/revenue-investments">
                View Investments
              </Button>
            </div>
          </div>
        )}

        {(user.accreditationStatus === false ||
          user.personalInfoCheck === false ||
          (user.businessInfoCheck === false &&
            user.subscriberType !== "individual") ||
          !user.uploadId ||
          user.uploadId === "" ||
          !user.uploadAccrediationDoc ||
          user.uploadAccrediationDoc === "") && (
          <div
            className={[classes.profileDetailsBox].join(",").replace(",", " ")}
          >
            <div>
              <i className="fal fa-user fa-user-check-verified"></i>
              <p>Complete Profile</p>
              <ul>
                <li>
                  {user.accreditationStatus === false ? (
                    <>
                      <i
                        className="fal fa-check-circle"
                        style={{ color: "#cccccc" }}
                      ></i>
                      <Link to="/accreditation-verification">
                        {" "}
                        Confirm Accreditation{" "}
                      </Link>
                    </>
                  ) : (
                    <>
                      <i
                        className="fas fa-check-circle"
                        style={{ color: "#6EC568" }}
                      ></i>
                      <p>Confirm Accreditation</p>
                    </>
                  )}
                </li>

                <li>
                  {!user.uploadId ||
                  user.uploadId === "" ||
                  !user.uploadAccrediationDoc ||
                  user.uploadAccrediationDoc === "" ? (
                    <>
                      <i
                        className="fal fa-check-circle"
                        style={{ color: "#cccccc" }}
                      ></i>
                      <Link to="/verification-process">
                        {" "}
                        Upload Accreditation Documents{" "}
                      </Link>
                    </>
                  ) : (
                    <>
                      <i
                        className="fas fa-check-circle"
                        style={{ color: "#6EC568" }}
                      ></i>
                      <p>Upload Accreditation Documents</p>
                    </>
                  )}
                </li>

                <li>
                  {user.personalInfoCheck === false ? (
                    <>
                      <i
                        className="fal fa-check-circle"
                        style={{ color: "#cccccc" }}
                      ></i>
                      <Link to="/personal-info"> Add Personal Info </Link>
                    </>
                  ) : (
                    <>
                      <i
                        className="fas fa-check-circle"
                        style={{ color: "#6EC568" }}
                      ></i>
                      <p> Add Personal Info</p>
                    </>
                  )}
                </li>

                {user.subscriberType !== "individual" && (
                  <li>
                    {user.businessInfoCheck === false ? (
                      <>
                        <i
                          className="fal fa-check-circle"
                          style={{ color: "#cccccc" }}
                        ></i>
                        <Link to="/business-info"> Add Business Info </Link>
                      </>
                    ) : (
                      <>
                        <i
                          className="fas fa-check-circle"
                          style={{ color: "#6EC568" }}
                        ></i>
                        <p> Add Business Info</p>
                      </>
                    )}
                  </li>
                )}

                {/* <li>
                                    <Link to="previous-investments"> Previous Investments </Link>
                                </li> */}
              </ul>
            </div>
          </div>
        )}

        {/* 
                    How many shares? 
                    (price set by admin)
                    Create a list of transactions, allow the user to remove shares for sale if nobody made offer to purchase.
                */}

        {/* <div className={classes.profileDetailsBox}>
                    <p>You can sell</p>
                    <span>{numeral(user.forSaleShares).format('0,0')} <span>/ shares</span></span>
                    <Button color="green" disabled>Sell Shares</Button>
                </div> */}
      </div>

      <h4>Account Notifications</h4>

      <div className={classes.profileDetailsNotifications}>
        {(!user.uploadId ||
          user.uploadId === "" ||
          !user.uploadAccrediationDoc ||
          user.uploadAccrediationDoc === "") &&
        pendingPayTrans.length > 0 ? (
          <div className={classes.profileDetailsNotificationsBox}>
            <i className="fal fa-exclamation-circle"></i>

            <div>
              <p>
                Transaction is on hold pending your acredited investor status
                verification.
              </p>
              <div className={classes.flexButton} style={{ marginTop: "2rem" }}>
                <Button color="white" onClick={onUpdateDocuments}>
                  Complete Now
                </Button>
              </div>
            </div>
          </div>
        ) : null}

        {pendingPayTrans.map((trans) => {
          return (
            <div
              key={trans._id}
              className={classes.profileDetailsNotificationsBox}
            >
              <i className="fal fa-exclamation-circle"></i>

              <div>
                <p>
                  Transaction {trans.contractId || trans.transferId} is on hold
                  pending payment completion.
                </p>
                <div
                  className={classes.flexButton}
                  style={{ marginTop: "2rem" }}
                >
                  <Button
                    color="white"
                    onClick={() => onGetPaymentDetails(trans._id)}
                  >
                    Payment Details
                  </Button>
                </div>
              </div>
            </div>
          );
        })}

        {(user.uploadId ||
          user.uploadId !== "" ||
          user.uploadAccrediationDoc ||
          user.uploadAccrediationDoc !== "") &&
          pendingPayTrans.length <= 0 && <p>You have no new notifications.</p>}
      </div>

      {/* <h4>FOLLOWONE INC. PPM</h4>

            <div className={classes.profileDetailsPresentation} style={{marginBottom: '4rem'}}>
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
                    <div style={{ height: '750px' }}>
                        <Viewer fileUrl={DocumentPPM} />
                    </div>
                </Worker>
            </div> */}

      <h4>BUSINESS PRESENTATION</h4>

      <div className={classes.profileDetailsPresentation}>
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
          <div className={classes.pdfSize}>
            <Viewer fileUrl={PDF} />
          </div>
        </Worker>
      </div>
    </div>
  );
};

export default ProfileDetails;

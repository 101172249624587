import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useAuth } from './shared/hooks/auth-hook';
import { AuthContext } from './shared/context/auth-context';

import classes from './App.module.scss';

import Header from './shared/components/Navigation/Header';
import Footer from './shared/components/Navigation/Footer';
import Modal from './shared/components/UIElements/Modal';

import Home from './pages/Home/Home';
import Dashboard from './pages/Dashboard/Dashboard';
import Transactions from './pages/Transactions/Transactions';
import ProfileSettings from './pages/ProfileSettings/ProfileSettings';
import Admin from './pages/Admin/Admin';

import CreateAccount from './pages/CreateAccount/Components/CreateAccount';
import AccreditationVerification from './pages/CreateAccount/Components/AccreditationVerification';
import PersonalInfo from './pages/CreateAccount/Components/PersonalInfo';
import BusinessInfo from './pages/CreateAccount/Components/BusinessInfo';
import PreviousInvestments from './pages/CreateAccount/Components/PreviousInvestments';
import Documents from './pages/Documents/Documents';

import Copyright from './pages/Policies/Copyright';
import PrivacyPolicies from './pages/Policies/PrivacyPolicies';
import PPM from './pages/Policies/PPM';
import SubscriptionAgreement from './pages/BuyShares/SubscriptionAgreement';
import BuyShares from './pages/BuyShares/BuyShares';
import PaymentInfo from './pages/BuyShares/PaymentInfo';
import VerificationProcess from './pages/BuyShares/VerificationProcess';
import Investments from './pages/Investments/Investments';
import AddInvestment from './pages/Admin/Components/AddInvestment';
import Investors from './pages/Investors/Investors';

const App = props => {

  const { token, userId, user, login, logout } = useAuth();

  const [footerModals, setFooterModals] = useState({
    copyright: false,
    privacy: false,
    terms: false,
    // ppm: false
  })

  const openModal = (type) => {
    setFooterModals({ [type]: true });
  }

  const closeModal = (type) => {
    setFooterModals({ [type]: false });
  }



  let routes;

  if (!token) {
    routes = (
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/reset-password/:token" exact component={Home} />
        <Route path="/create-account" exact component={CreateAccount} />
        <Route path="/investors" exact component={Investors} />
        <Route path="*" component={Home} />
      </Switch>
    )
  } else {
    routes = (
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/dashboard" exact component={Dashboard} />
        <Route path="/equity-investments/:investorId" exact render={() => <Investments title="Equity Investments" type="equity" />} />
        <Route path="/equity-investments" exact render={() => <Investments title="Equity Investments" type="equity" />} />
        <Route path="/revenue-investments/:investorId" exact render={() => <Investments title="Revenue Investments" type="revenue" />} />
        <Route path="/revenue-investments" exact render={() => <Investments title="Revenue Investments" type="revenue" />} />
        <Route path="/transactions" exact component={Transactions} />
        <Route path="/profile-settings" exact component={ProfileSettings} />
        {user && user.role === "superadmin" ? <Route path="/admin" exact component={Admin} /> : null}
        {user && user.role === "superadmin" ? <Route path="/admin/:adminPage" exact component={Admin} /> : null}
        {user && user.role === "superadmin" ? <Route path="/admin/edit-investment/:investmentId" exact component={AddInvestment} /> : null}
        {user && (user.role === "superadmin" || user.role === 'institution') ? <Route path="/documents" exact component={Documents} /> : null}
        {/* <Route path="/buy-shares/" exact component={BuyShares} /> */}
        {/* <Route path="/buy-shares/" exact component={Dashboard} /> */}
        {/* <Route path="/subscription-agreement/:id" exact component={SubscriptionAgreement} /> */}
        {/* <Route path="/subscription-agreement/:id" exact component={Dashboard} /> */}
        <Route path="/payment-information/:id" exact component={PaymentInfo} />
        <Route path="/verification-process" exact component={VerificationProcess} />
        <Route path="/accreditation-verification/" exact component={AccreditationVerification} />
        <Route path="/personal-info/" exact component={PersonalInfo} />
        <Route path="/business-info/" exact component={BusinessInfo} />
        <Route path="/previous-investments/" exact component={PreviousInvestments} />
        <Route path="/investors" exact component={Investors} />
        <Route path="*" component={Home} />
      </Switch>
    )
  }

  return (
    <AuthContext.Provider value={{
      isLoggedIn: !!token,
      token: token,
      userId: userId,
      user: user,
      login: login,
      logout: logout
    }}>

      <div className={classes.App}>

        <Header />
          {routes}
        <Footer openModal={openModal} />

        {/* Footer Modals */}

        <Modal
          show={footerModals.ppm}
          onCancel={() => { closeModal('ppm') }}
          header="PPM"
          size="regular"
        >
          <PPM />
        </Modal>

        <Modal
          show={footerModals.privacy}
          onCancel={() => { closeModal('privacy') }}
          header="Privacy Policy"
          size="large"
        >
          <PrivacyPolicies />
        </Modal>

        <Modal
          show={footerModals.copyright}
          onCancel={() => { closeModal('copyright') }}
          header="Copyright"
        >
          <Copyright />
        </Modal>

      </div>
    </AuthContext.Provider >
  );
}

export default App;
